body {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: var(--background);
  color: var(--text);
  font-family: var(--body);
  text-align: center;
  line-height: var(--compact);
}
